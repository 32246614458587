import { request } from './utils';

import {
  SubscriptionsResponse,
  GetSubscriptionsRequestOptions
} from './interfaces';

export function getSubscriptions({ query }: GetSubscriptionsRequestOptions): Promise<SubscriptionsResponse> {
  return request({
    url: '/subscriptions',
    method: 'get',
    params: query
  });
}
