import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const roleRouter: Array<RouteRecordRaw> = [
  {
    path: '/roles',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'roles',
      icon: '#icon-rolepermission'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "role-create" */ '@/views/roles/Create.vue'),
        name: 'create-role',
        meta: {
          title: 'createRole',
          activeMenu: '/roles',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "role-edit" */ '@/views/roles/Edit.vue'),
        name: 'edit-role',
        meta: {
          title: 'editRole',
          noCache: true,
          activeMenu: '/roles',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "role-list" */ '@/views/roles/List.vue'),
        name: 'list-roles',
        meta: {
          title: 'roles',
          icon: '#icon-rolepermission'
        }
      }
    ]
  }
];

export default roleRouter;
