import { ref } from 'vue';

export const isValidUsername = (str: string) => ['admin', 'editor'].indexOf(str.trim()) >= 0;

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

export const isArray = (arg: any) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
};

export const isValidURL = (url: string) => {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

/**
 * at least 1 letter and 1 number
 * @param {string} password
 */
export const validatePassword = (password: any) => {
  if (password === '' || password === undefined) {
    return new Error('Please input the password.');
  } else if (password !== '') {
    if (!password.match(/([a-z])+/)) {
      return new Error('Your password does not contain any letter.');
    }
    if (!password.match(/([0-9])+/)) {
      return new Error('Your password does not contain any number.');
    }
    if (password.match(/([a-z])+/) &&
        password.match(/([0-9])+/)) {
      return true;
    } else {
      return new Error('Please make sure your password contains at least one number and one letter.');
    }
  }
};

// TODO: should move to composables
/**
 * el-form-item表單檢查
 * 顯示PHP laraval request error msg到表單item上
 * 使用方式見 IPWhitelistDetail.vue
 *
 */
export default function() {
  /**
   * 錯誤的訊息
   * {
   *  title:['msg'],
   *  sort:['msg']
   * }
   *  */
  const formErrors = ref({});
  // 綁定:error後，propName檢查是否有該物件
  function bindFormItemError(propName: string) {
    return formErrors.value != null &&
      // eslint-disable-next-line no-prototype-builtins
      formErrors.value.hasOwnProperty(propName) &&
      formErrors.value[propName].length > 0
      ? formErrors.value[propName][0]
      : null;
  };
  return {
    formErrors,
    bindFormItemError
  };
}
