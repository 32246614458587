import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const CommentReportRouter: Array<RouteRecordRaw> = [
  {
    path: '/comments/reports',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'comment reports',
      icon: '#icon-report'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/comments/Report.vue'),
        name: 'list-comment-reports',
        meta: {
          title: 'commentReports',
          icon: '#icon-report'
        }
      }
    ]
  }
];

export default CommentReportRouter;
