import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const OrderRouter: Array<RouteRecordRaw> = [
  {
    path: '/orders',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'orders',
      icon: '#icon-order'
    },
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "order-edit" */ '@/views/orders/Edit.vue'),
        name: 'edit-order',
        meta: {
          title: 'editOrder',
          noCache: true,
          activeMenu: '/orders',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "order-list" */ '@/views/orders/List.vue'),
        name: 'list-orders',
        meta: {
          title: 'orders',
          icon: '#icon-order'
        }
      }
    ]
  }
];

export default OrderRouter;
