import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const whitelistRouter: Array<RouteRecordRaw> = [
  {
    path: '/ipwhitelists',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'ip-whitelist',
      icon: '#icon-whitelist'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "admin-create" */ '@/views/ip-whitelist/Create.vue'),
        name: 'create-ipwhitelist',
        meta: {
          title: 'createWhitelist',
          activeMenu: '/ip-whitelist',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/ip-whitelist/Edit.vue'),
        name: 'edit-ipwhitelist',
        meta: {
          title: 'editWhitelist',
          noCache: true,
          activeMenu: '/ip-whitelist',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/ip-whitelist/List.vue'),
        name: 'list-ipwhitelist',
        meta: {
          title: 'ip-whitelist',
          icon: '#icon-whitelist'
        }
      }
    ]
  }
];

export default whitelistRouter;
