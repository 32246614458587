import { request } from './utils';

import {
  IPWhitelistResponse,
  GetIPWhitelistRequestOptions,
  CreateIPAdressRequestOptions,
  UpdateIPAddressRequestOptions,
  DeleteIPAddressRequestOptions,
  DeleteResponse,
  GetIPWhitelistsRequestOptions,
  IPWhitelistsResponse
} from './interfaces';

export function getIPWhitelist({ whitelistId }: GetIPWhitelistRequestOptions): Promise<IPWhitelistResponse> {
  return request({
    url: `/ipwhitelists/${whitelistId}`,
    method: 'get'
  });
}

export function getIPWhitelists({ query }: GetIPWhitelistsRequestOptions = {}): Promise<IPWhitelistsResponse> {
  return request({
    url: '/ipwhitelists',
    method: 'get',
    params: query
  });
}

export function createIPAdress({ data }: CreateIPAdressRequestOptions): Promise<IPWhitelistResponse> {
  return request({
    url: '/ipwhitelists',
    method: 'post',
    data
  });
}

export function updateIPAddress({ whitelistId, data }: UpdateIPAddressRequestOptions): Promise<IPWhitelistResponse> {
  return request({
    url: `/ipwhitelists/${whitelistId}`,
    method: 'put',
    data
  });
}

export function deleteIPAddress({ whitelistId }: DeleteIPAddressRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/ipwhitelists/${whitelistId}`,
    method: 'delete'
  });
}
