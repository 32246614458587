
import { defineComponent } from 'vue';
import settings from '@/config/default/setting.config';

export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const title = settings.title;
    const Logo = `https://www.modelmedia${process.env.VUE_APP_THEME}.com/images/logo/theme-${process.env.VUE_APP_THEME}/logo.png`;

    return {
      title,
      Logo
    };
  }
});
