import { request } from './utils';

import {
  RoleResponse,
  RolesResponse,
  GetRoleRequestOptions,
  GetRolesRequestOptions,
  CreateRoleRequestOptions,
  UpdateRoleRequestOptions,
  DeleteRoleRequestOptions,
  DeleteResponse,
  UpdateRoleToAdminsRequestOptions,
  AdminResponse
} from './interfaces';
import { Role } from '@/interfaces/Role';

export function getRole({ roleId }: GetRoleRequestOptions): Promise<RoleResponse> {
  return request({
    // eslint-disable-next-line @typescript-eslint/camelcase
    url: `/roles/${roleId}`,
    method: 'get'
  });
}

export function getRoles({ query }: GetRolesRequestOptions): Promise<RolesResponse> {
  return request({
    url: '/roles',
    method: 'get',
    params: query
  });
}

export function createRole({ data }: CreateRoleRequestOptions): Promise<RoleResponse> {
  return request({
    url: '/roles',
    method: 'post',
    data
  });
}

export function updateRole({ roleId, data }: UpdateRoleRequestOptions): Promise<RoleResponse> {
  return request({
    url: `/roles/${roleId}`,
    method: 'put',
    data
  });
}

export function updateRoleToAdmins({ roleId, data }: UpdateRoleToAdminsRequestOptions): Promise<AdminResponse> {
  return request({
    url: `roles/${roleId}/assign`,
    method: 'post',
    data
  });
}

export function deleteRole({ roleId }: DeleteRoleRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/roles/${roleId}`,
    method: 'delete'
  });
}
