import { request } from './utils';

import {
  PlanResponse,
  PlansResponse,
  GetPlanRequestOptions,
  GetPlansRequestOptions,
  CreatePlanRequestOptions,
  UpdatePlanRequestOptions,
  DeletePostRequestOptions,
  DeleteResponse
} from './interfaces';

export function getPlan({ planId }: GetPlanRequestOptions): Promise<PlanResponse> {
  return request({
    url: `/plans/${planId}`,
    method: 'get'
  });
}

export function getPlans({ query }: GetPlansRequestOptions): Promise<PlansResponse> {
  return request({
    url: '/plans',
    method: 'get',
    params: query
  });
}

export function createPlan({ data }: CreatePlanRequestOptions): Promise<PlanResponse> {
  return request({
    url: '/plans',
    method: 'post',
    data
  });
}

export function updatePlan({ planId, data }: UpdatePlanRequestOptions): Promise<PlanResponse> {
  return request({
    url: `/plans/${planId}`,
    method: 'put',
    data
  });
}

export function deletePost({ postId }: DeletePostRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/posts/${postId}`,
    method: 'delete'
  });
}
