import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const blockRouter: Array<RouteRecordRaw> = [
  {
    path: '/blocks',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'blocks',
      icon: '#icon-block'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "block-create" */ '@/views/blocks/Create.vue'),
        name: 'create-block',
        meta: {
          title: 'createBlock',
          activeMenu: '/blocks',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "block-edit" */ '@/views/blocks/Edit.vue'),
        name: 'edit-block',
        meta: {
          title: 'editBlock',
          noCache: true,
          activeMenu: '/blocks',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "block-list" */ '@/views/blocks/List.vue'),
        name: 'list-blocks',
        meta: {
          title: 'blocks',
          icon: '#icon-block'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "block-show" */ '@/views/blocks/Show.vue'),
        name: 'show-block',
        meta: {
          title: 'showBlock',
          noCache: true,
          activeMenu: '/blocks',
          hidden: true
        }
      }
    ]
  }
];

export default blockRouter;
