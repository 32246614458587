import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const postRouter: Array<RouteRecordRaw> = [
  {
    path: '/posts',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'posts',
      icon: '#icon-post'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "post-create" */ '@/views/posts/Create.vue'),
        name: 'create-post',
        meta: {
          title: 'createPost',
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "post-edit" */ '@/views/posts/Edit.vue'),
        name: 'edit-post',
        meta: {
          title: 'editPost',
          noCache: true,
          activeMenu: '/posts',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "post-list" */ '@/views/posts/List.vue'),
        name: 'list-posts',
        meta: {
          title: 'posts',
          icon: '#icon-post'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "post-show" */ '@/views/posts/Show.vue'),
        name: 'show-post',
        meta: {
          title: 'showPost',
          noCache: true,
          activeMenu: '/posts',
          hidden: true
        }
      }
    ]
  }
];

export default postRouter;
