import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const adminRouter: Array<RouteRecordRaw> = [
  {
    path: '/admins',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'admins',
      icon: '#icon-admin'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "admin-create" */ '@/views/admins/Create.vue'),
        name: 'create-admin',
        meta: {
          title: 'createAdmin',
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/admins/Edit.vue'),
        name: 'edit-admin',
        meta: {
          title: 'editAdmin',
          noCache: true,
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/admins/List.vue'),
        name: 'list-admins',
        meta: {
          title: 'admins',
          icon: '#icon-admin'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-show" */ '@/views/admins/Show.vue'),
        name: 'show-admin',
        meta: {
          title: 'showAdmin',
          noCache: true,
          activeMenu: '/admins',
          hidden: true
        }
      }
    ]
  }
];

export default adminRouter;
