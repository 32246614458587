import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const promotionRouter: Array<RouteRecordRaw> = [
  {
    path: '/promotions',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'promotions',
      icon: '#icon-promotion'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "promotion-create" */ '@/views/promotions/Create.vue'),
        name: 'create-promotion',
        meta: {
          title: 'createPromotion',
          activeMenu: '/promotions',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "promotion-edit" */ '@/views/promotions/Edit.vue'),
        name: 'edit-promotion',
        meta: {
          title: 'editPromotion',
          noCache: true,
          activeMenu: '/promotions',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "promotion-list" */ '@/views/promotions/List.vue'),
        name: 'list-promotions',
        meta: {
          title: 'promotions',
          icon: '#icon-promotion'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "promotion-show" */ '@/views/promotions/Show.vue'),
        name: 'show-promotion',
        meta: {
          title: 'showPromotion',
          noCache: true,
          activeMenu: '/promotions',
          hidden: true
        }
      }
    ]
  }
];

export default promotionRouter;
