import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const videoRouter: Array<RouteRecordRaw> = [
  {
    path: '/videos',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'videos',
      icon: '#icon-video'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "video-create" */ '@/views/videos/Create.vue'),
        name: 'create-video',
        meta: {
          title: 'createVideo',
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: 'batch-create',
        component: () => import(/* webpackChunkName: "video-create" */ '@/views/videos/BatchCreate.vue'),
        name: 'batch-create-video',
        meta: {
          title: 'batchCreateVideo',
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "video-edit" */ '@/views/videos/Edit.vue'),
        name: 'edit-video',
        meta: {
          title: 'editVideo',
          noCache: true,
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: 'show/:id',
        component: () => import(/* webpackChunkName: "video-edit" */ '@/views/videos/Show.vue'),
        name: 'show-video',
        meta: {
          title: 'showVideo',
          noCache: true,
          activeMenu: '/videos',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "video-list" */ '@/views/videos/List.vue'),
        name: 'list-videos',
        meta: {
          title: 'videos',
          icon: '#icon-video'
        }
      }
    ]
  }
];

export default videoRouter;
