import { request } from './utils';

import {
  CooperationResponse,
  CooperationsResponse,
  GetCooperationRequestOptions,
  GetCooperationsRequestOptions,
  DeleteCooperationRequestOptions,
  DeleteResponse,
  SolveCooperationRequestOptions,
  SolveResponse,
  ArchiveCooperationRequestOptions,
  ArchiveResponse
} from './interfaces';

export function getCooperation({ cooperationId }: GetCooperationRequestOptions): Promise<CooperationResponse> {
  return request({
    url: `/cooperations/${cooperationId}`,
    method: 'get'
  });
}

export function getCooperations({ query }: GetCooperationsRequestOptions): Promise<CooperationsResponse> {
  return request({
    url: '/cooperations',
    method: 'get',
    params: query
  });
}

export function deleteCooperation({ cooperationId }: DeleteCooperationRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/cooperations/${cooperationId}`,
    method: 'delete'
  });
}

export function solveCooperation({ cooperationId }: SolveCooperationRequestOptions): Promise<SolveResponse> {
  return request({
    url: `/cooperations/${cooperationId}/solve`,
    method: 'post'
  });
}

export function archiveCooperation({ cooperationId }: ArchiveCooperationRequestOptions): Promise<ArchiveResponse> {
  return request({
    url: `/cooperations/${cooperationId}/archive`,
    method: 'post'
  });
}
