import { request } from './utils';

import {
  AdminResponse,
  AdminsResponse,
  GetAdminRequestOptions,
  GetAdminsRequestOptions,
  CreateAdminRequestOptions,
  UpdateAdminRequestOptions,
  DeleteAdminRequestOptions,
  EnableAdminRequestOptions,
  DisableAdminRequestOptions,
  DeleteResponse,
  EnableResponse,
  DisableResponse
} from './interfaces';

export function getAdmin({ adminId }: GetAdminRequestOptions): Promise<AdminResponse> {
  return request({
    url: `/admins/${adminId}`,
    method: 'get'
  });
}

export function getAdmins({ query }: GetAdminsRequestOptions = {}): Promise<AdminsResponse> {
  return request({
    url: '/admins',
    method: 'get',
    params: query
  });
}

export function createAdmin({ data }: CreateAdminRequestOptions): Promise<AdminResponse> {
  return request({
    url: '/admins',
    method: 'post',
    data
  });
}

export function updateAdmin({ adminId, data }: UpdateAdminRequestOptions): Promise<AdminResponse> {
  return request({
    url: `/admins/${adminId}`,
    method: 'put',
    data
  });
}

export function deleteAdmin({ adminId }: DeleteAdminRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/admins/${adminId}`,
    method: 'delete'
  });
}

export function resetQRCodeAdmin({ adminId }: DeleteAdminRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/admins/${adminId}/resetQRCode`,
    method: 'post'
  });
}

export function enableAdmin({ adminId }: EnableAdminRequestOptions): Promise<EnableResponse> {
  return request({
    url: `/admins/${adminId}/enable`,
    method: 'post'
  });
}

export function disableAdmin({ adminId }: DisableAdminRequestOptions): Promise<DisableResponse> {
  return request({
    url: `/admins/${adminId}/disable`,
    method: 'post'
  });
}
