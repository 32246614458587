import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const InformationRouter: Array<RouteRecordRaw> = [
  {
    path: '/informations',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'informations',
      icon: '#icon-info'
    },
    children: [
      {
        path: ':id(\\d+)/edit',
        component: () => import(/* webpackChunkName: "information-edit" */ '@/views/informations/Edit.vue'),
        name: 'edit-information',
        meta: {
          title: 'editInformation',
          noCache: true,
          activeMenu: '/informations',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "information-list" */ '@/views/informations/List.vue'),
        name: 'list-informations',
        meta: {
          title: 'informations',
          icon: '#icon-info'
        }
      }
    ]
  }
];

export default InformationRouter;
