import { request, createFormData } from './utils';

import {
  UploadImageRequestOptions,
  ImageResponse,
  UploadFileRequestOptions,
  FileResponse,
  CheckFileOnCloudRequestOptions,
  CheckFileOnCloudResponse,
  CheckFileOnServerRequestOptions,
  CheckFileOnServerResponse,
  UploadToCloudRequestOptions,
  UploadToCloudResponse,
  CheckChunkListRequestOptions,
  CheckChunkListResponse,
  UploadVideoRequestOptions,
  UploadVideoResponse
} from './interfaces';

export function uploadImage({
  data
}: UploadImageRequestOptions): Promise<ImageResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: '/files/images',
    method: 'post',
    data: createFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function uploadVideo({
  data
}: UploadVideoRequestOptions): Promise<UploadVideoResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: '/files/videos',
    method: 'post',
    data: createFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function uploadFile({
  data
}: UploadFileRequestOptions): Promise<FileResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: '/files/upload',
    method: 'post',
    data: createFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function checkFileOnCloud({
  query
}: CheckFileOnCloudRequestOptions): Promise<CheckFileOnCloudResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/checkFileOnCloud',
    method: 'get',
    params: query
  });
}

export function checkFileOnServer({
  query
}: CheckFileOnServerRequestOptions): Promise<CheckFileOnServerResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/checkFileOnServer',
    method: 'get',
    params: query
  });
}

export function uploadToCloud({
  data
}: UploadToCloudRequestOptions): Promise<UploadToCloudResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/putfile',
    method: 'post',
    data
  });
}

export function checkChunkList({
  query
}: CheckChunkListRequestOptions): Promise<CheckChunkListResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: 'files/checkBlockList',
    method: 'get',
    params: query
  });
}
