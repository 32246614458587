import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const QuestionRouter: Array<RouteRecordRaw> = [
  {
    path: '/daily-data',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'daily-data',
      icon: '#icon-analysis'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "daily-data" */ '@/views/daliy-data/List.vue'),
        name: 'list-daily-data',
        meta: {
          title: 'daily-data',
          icon: '#icon-analysis'
        }
      }
    ]
  }
];

export default QuestionRouter;
