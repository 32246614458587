import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const bannerRouter: Array<RouteRecordRaw> = [
  {
    path: '/banners',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'banners',
      icon: '#icon-banner'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "banner-create" */ '@/views/banners/Create.vue'),
        name: 'create-banner',
        meta: {
          title: 'createBanner',
          activeMenu: '/banners',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "banner-edit" */ '@/views/banners/Edit.vue'),
        name: 'edit-banner',
        meta: {
          title: 'editBanner',
          noCache: true,
          activeMenu: '/banners',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "banner-show" */ '@/views/banners/Show.vue'),
        name: 'show-banner',
        meta: {
          title: 'showBanner',
          noCache: true,
          activeMenu: '/banners',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "banner-list" */ '@/views/banners/List.vue'),
        name: 'list-banners',
        meta: {
          title: 'banners',
          icon: '#icon-banner'
        }
      }
    ]
  }
];

export default bannerRouter;
