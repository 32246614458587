import { useStore } from '@/store';
import networkConfig from '@/config/default/net.config';

import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { UserActionTypes } from '@/store/modules/user/action-types';

function toQS(obj: object = {}) {
  return new URLSearchParams(JSON.parse(JSON.stringify(obj))).toString();
}

export function createHTTPClient(options: AxiosRequestConfig = {}) {
  const request = axios.create({
    baseURL: networkConfig.host,
    ...options
  });

  // request interceptor
  request.interceptors.request.use(
    (config) => {
      config.headers['X-Requested-With'] = 'XMLHttpRequest';

      const { token } = useStore().state.user;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      console.error(error);

      return Promise.reject(error);
    }
  );

  // response interceptor
  request.interceptors.response.use(
    (response) => {
      const { config, data } = response;
      const style = 'color: lightGreen';
      const qs = toQS(config.params);
      console.log(
        `%c ${config.method} ${config.url}${qs ? `?${qs}` : ''} 😁 `,
        style,
        response
      );

      return data;
    },
    (error: AxiosError) => {
      const { config } = error;
      const qs = toQS(config.params);
      console.error(`${config.method} ${config.url}${qs ? `?${qs}` : ''} 😭 `, {
        ...error
      });

      if (error?.response?.status === 401) {
        useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined);
        window.location.replace('/');
      }

      return Promise.reject(error);
    }
  );

  return request;
}
