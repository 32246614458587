import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const SubscriptionRouter: Array<RouteRecordRaw> = [
  {
    path: '/subscriptions',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'subscriptions',
      icon: '#icon-subscription'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "subscription-list" */ '@/views/subscriptions/List.vue'),
        name: 'list-subscriptions',
        meta: {
          title: 'subscriptions',
          icon: '#icon-subscription'
        }
      }
    ]
  }
];

export default SubscriptionRouter;
