import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const segpayRouter: Array<RouteRecordRaw> = [
  {
    path: '/segpays',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'segpays',
      icon: '#icon-paymentmethod'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "segpay-create" */ '@/views/segpays/Create.vue'),
        name: 'create-segpay',
        meta: {
          title: 'createSegpay',
          activeMenu: '/segpays',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "segpay-edit" */ '@/views/segpays/Edit.vue'),
        name: 'edit-segpay',
        meta: {
          title: 'editSegpay',
          noCache: true,
          activeMenu: '/segpays',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "segpay-list" */ '@/views/segpays/List.vue'),
        name: 'list-segpay',
        meta: {
          title: 'segpays',
          icon: '#icon-paymentmethod'
        }
      }
      // {
      //   path: ':id(\\d+)',
      //   component: () => import(/* webpackChunkName: "segpay-edit" */ '@/views/segpays/Show.vue'),
      //   name: 'show-segpay',
      //   meta: {
      //     title: 'showSegpay',
      //     noCache: true,
      //     activeMenu: '/segpay',
      //     hidden: true
      //   }
      // }
    ]
  }
];

export default segpayRouter;
