import { request } from './utils';

import {
  CommentsResponse,
  GetCommentsRequestOptions,
  DeleteCommentRequestOptions,
  DeleteResponse,
  EnableCommentRequestOptions,
  EnableResponse,
  DisableCommentRequestOptions,
  DisableResponse,
  BanCommentRequestOptions,
  BanResponse,
  RejectReportCommentRequestOptions,
  RejectReportResponse
} from './interfaces';

export function getComments({ query }: GetCommentsRequestOptions): Promise<CommentsResponse> {
  return request({
    url: '/comments',
    method: 'get',
    params: query
  });
}

export function deleteComment({ commentId }: DeleteCommentRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/comments/${commentId}`,
    method: 'delete'
  });
}

export function enableComment({ commentId }: EnableCommentRequestOptions): Promise<EnableResponse> {
  return request({
    url: `/comments/${commentId}/enable`,
    method: 'post'
  });
}

export function disableComment({ commentId }: DisableCommentRequestOptions): Promise<DisableResponse> {
  return request({
    url: `/comments/${commentId}/disable`,
    method: 'post'
  });
}

export function getCommentsReport({ query }: GetCommentsRequestOptions): Promise<CommentsResponse> {
  return request({
    url: '/comments/reports',
    method: 'get',
    params: query
  });
}

export function banComment({ commentId }: BanCommentRequestOptions): Promise<BanResponse> {
  return request({
    url: `/comments/${commentId}/ban`,
    method: 'post'
  });
}

export function rejectReportComment({ commentId }: RejectReportCommentRequestOptions): Promise<RejectReportResponse> {
  return request({
    url: `/comments/${commentId}/reject-report`,
    method: 'post'
  });
}
