import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const dealRouter: Array<RouteRecordRaw> = [
  {
    path: '/deals',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'deals',
      icon: '#icon-deal'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "deal-create" */ '@/views/deals/Create.vue'),
        name: 'create-deal',
        meta: {
          title: 'createDeal',
          activeMenu: '/deals',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "deal-edit" */ '@/views/deals/Edit.vue'),
        name: 'edit-deal',
        meta: {
          title: 'editDeal',
          noCache: true,
          activeMenu: '/deals',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "deal-list" */ '@/views/deals/List.vue'),
        name: 'list-deals',
        meta: {
          title: 'deals',
          icon: '#icon-deal'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "deal-edit" */ '@/views/deals/Show.vue'),
        name: 'show-deal',
        meta: {
          title: 'showDeal',
          noCache: true,
          activeMenu: '/deals',
          hidden: true
        }
      }
    ]
  }
];

export default dealRouter;
