import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const CooperationRouter: Array<RouteRecordRaw> = [
  {
    path: '/cooperations',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'cooperations',
      icon: '#icon-cooperation'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "cooperation-list" */ '@/views/cooperations/List.vue'),
        name: 'list-cooperations',
        meta: {
          title: 'cooperations',
          icon: '#icon-cooperation'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "cooperation-show" */ '@/views/cooperations/Show.vue'),
        name: 'show-cooperation',
        meta: {
          title: 'showCooperation',
          noCache: true,
          activeMenu: '/cooperations',
          hidden: true
        }
      }
    ]
  }
];

export default CooperationRouter;
