import { request } from './utils';

import {
  UserResponse,
  UsersResponse,
  GetUserRequestOptions,
  GetUsersRequestOptions,
  UpdateUserRequestOptions,
  DisableUserRequestOptions,
  DisableResponse,
  EnableUserRequestOptions,
  EnableResponse,
  SendEmailUserRequestOptions,
  SendResetUserRequestOptions,
  SendResponse,
  BatchCreateUserRequestOptions
} from './interfaces';

export function getUser({ userId }: GetUserRequestOptions): Promise<UserResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'get'
  });
}

export function getUsers({ query }: GetUsersRequestOptions): Promise<UsersResponse> {
  return request({
    url: '/users',
    method: 'get',
    params: query
  });
}

export function batchCreateUser({ data }: BatchCreateUserRequestOptions): Promise<UserResponse> {
  return request({
    url: 'users/batch-create',
    method: 'post',
    data
  });
}

export function updateUser({ userId, data }: UpdateUserRequestOptions): Promise<UserResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'put',
    data
  });
}

export function disableUser({ userId }: DisableUserRequestOptions): Promise<DisableResponse> {
  return request({
    url: `/users/${userId}/disable`,
    method: 'post'
  });
}

export function enableUser({ userId }: EnableUserRequestOptions): Promise<EnableResponse> {
  return request({
    url: `/users/${userId}/enable`,
    method: 'post'
  });
}

export function sendEmailUser({ userId }: SendEmailUserRequestOptions): Promise<SendResponse> {
  return request({
    url: `/users/${userId}/send-email-verification`,
    method: 'get'
  });
}

export function sendResetUser({ userId }: SendResetUserRequestOptions): Promise<SendResponse> {
  return request({
    url: `/users/${userId}/send-reset-password`,
    method: 'get'
  });
}
