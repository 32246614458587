import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const PlanRouter: Array<RouteRecordRaw> = [
  {
    path: '/plans',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'plans',
      icon: '#icon-plan'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "plan-create" */ '@/views/plans/Create.vue'),
        name: 'create-plan',
        meta: {
          title: 'createPlan',
          activeMenu: '/plans',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/edit',
        component: () => import(/* webpackChunkName: "plan-edit" */ '@/views/plans/Edit.vue'),
        name: 'edit-plan',
        meta: {
          title: 'editPlan',
          noCache: true,
          activeMenu: '/plans',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "plan-show" */ '@/views/plans/Show.vue'),
        name: 'show-plan',
        meta: {
          title: 'showPlan',
          noCache: true,
          activeMenu: '/plans',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "plan-list" */ '@/views/plans/List.vue'),
        name: 'list-plans',
        meta: {
          title: 'plans',
          icon: '#icon-plan'
        }
      }
    ]
  }
];

export default PlanRouter;
