import { request } from './utils';

import {
  ModelResponse,
  ModelsResponse,
  GetModelRequestOptions,
  GetModelsRequestOptions,
  CreateModelRequestOptions,
  UpdateModelRequestOptions,
  DeleteModelRequestOptions,
  DeleteResponse,
  PhotoResponse,
  CreateModelPhotoRequestOptions,
  DeleteModelPhotoRequestOptions,
  GetBlockModelsRequestOptions,
  BlockModelsResponse
} from './interfaces';

export function getModel({ modelId }: GetModelRequestOptions): Promise<ModelResponse> {
  return request({
    url: `/models/${modelId}`,
    method: 'get'
  });
}

export function getModels({ query }: GetModelsRequestOptions = {}): Promise<ModelsResponse> {
  return request({
    url: '/models',
    method: 'get',
    params: query
  });
}

export function getBlockModels({ blockId, query }: GetBlockModelsRequestOptions): Promise<BlockModelsResponse> {
  return request({
    url: `blocks/${blockId}/models`,
    method: 'get',
    params: query
  });
}

export function createModel({ data }: CreateModelRequestOptions): Promise<ModelResponse> {
  return request({
    url: '/models',
    method: 'post',
    data
  });
}

export function updateModel({ modelId, data }: UpdateModelRequestOptions): Promise<ModelResponse> {
  return request({
    url: `/models/${modelId}`,
    method: 'put',
    data
  });
}

export function deleteModel({ modelId }: DeleteModelRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/models/${modelId}`,
    method: 'delete'
  });
}

export function createModelPhoto({
  modelId,
  data
}: CreateModelPhotoRequestOptions): Promise<PhotoResponse> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return request({
    url: `/models/${modelId}/photos`,
    method: 'post',
    data
  });
}

export function deleteModelPhoto({
  modelId,
  photoId
}: DeleteModelPhotoRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/models/${modelId}/photos/${photoId}`,
    method: 'delete'
  });
}
