import { request } from './utils';

import {
  SupportResponse,
  SupportsResponse,
  GetSupportRequestOptions,
  GetSupportsRequestOptions,
  DeleteSupportRequestOptions,
  DeleteResponse,
  SolveSupportRequestOptions,
  SolveResponse,
  ArchiveSupportRequestOptions,
  ArchiveResponse
} from './interfaces';

export function getSupport({ supportId }: GetSupportRequestOptions): Promise<SupportResponse> {
  return request({
    url: `/supports/${supportId}`,
    method: 'get'
  });
}

export function getSupports({ query }: GetSupportsRequestOptions): Promise<SupportsResponse> {
  return request({
    url: '/supports',
    method: 'get',
    params: query
  });
}

export function deleteSupport({ supportId }: DeleteSupportRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/supports/${supportId}`,
    method: 'delete'
  });
}

export function solveSupport({ supportId }: SolveSupportRequestOptions): Promise<SolveResponse> {
  return request({
    url: `/supports/${supportId}/solve`,
    method: 'post'
  });
}

export function archiveSupport({ supportId }: ArchiveSupportRequestOptions): Promise<ArchiveResponse> {
  return request({
    url: `/supports/${supportId}/archive`,
    method: 'post'
  });
}
