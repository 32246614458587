import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const modelRouter: Array<RouteRecordRaw> = [
  {
    path: '/models',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'models',
      icon: '#icon-model'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "model-create" */ '@/views/models/Create.vue'),
        name: 'create-model',
        meta: {
          title: 'createModel',
          activeMenu: '/models',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "model-edit" */ '@/views/models/Edit.vue'),
        name: 'edit-model',
        meta: {
          title: 'editModel',
          noCache: true,
          activeMenu: '/models',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "model-list" */ '@/views/models/List.vue'),
        name: 'list-models',
        meta: {
          title: 'models',
          icon: '#icon-model'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "model-show" */ '@/views/models/Show.vue'),
        name: 'show-model',
        meta: {
          title: 'showModel',
          noCache: true,
          activeMenu: '/models',
          hidden: true
        }
      }
    ]
  }
];

export default modelRouter;
