import { request } from './utils';

import {
  BannerResponse,
  BannersResponse,
  GetBannerRequestOptions,
  GetBannersRequestOptions,
  CreateBannerRequestOptions,
  UpdateBannerRequestOptions,
  DeleteBannerRequestOptions,
  DeleteResponse
} from './interfaces';

export function getBanner({ bannerId }: GetBannerRequestOptions): Promise<BannerResponse> {
  return request({
    url: `/banners/${bannerId}`,
    method: 'get'
  });
}

export function getBanners({ query }: GetBannersRequestOptions): Promise<BannersResponse> {
  return request({
    url: '/banners',
    method: 'get',
    params: query
  });
}

export function createBanner({ data }: CreateBannerRequestOptions): Promise<BannerResponse> {
  return request({
    url: '/banners',
    method: 'post',
    data
  });
}

export function updateBanner({ bannerId, data }: UpdateBannerRequestOptions): Promise<BannerResponse> {
  return request({
    url: `/banners/${bannerId}`,
    method: 'put',
    data
  });
}

export function deleteBanner({ bannerId }: DeleteBannerRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/banners/${bannerId}`,
    method: 'delete'
  });
}
