import { request } from './utils';

import {
  DirectorsResponse,
  GetDirectorsRequestOptions,
  GetDirectorRequestOptions,
  DirectorResponse,
  CreateDirectorRequestOptions,
  UpdateDirectorRequestOptions,
  DeleteDirectorRequestOptions,
  DeleteResponse
} from './interfaces';

export function getDirector({ directorId }: GetDirectorRequestOptions): Promise<DirectorResponse> {
  return request({
    url: `/directors/${directorId}`,
    method: 'get'
  });
}

export function getDirectors({ query }: GetDirectorsRequestOptions = {}): Promise<DirectorsResponse> {
  return request({
    url: '/directors',
    method: 'get',
    params: query
  });
}

export function createDirector({ data }: CreateDirectorRequestOptions): Promise<DirectorResponse> {
  return request({
    url: '/directors',
    method: 'post',
    data
  });
}

export function updateDirector({ directorId, data }: UpdateDirectorRequestOptions): Promise<DirectorResponse> {
  return request({
    url: `/directors/${directorId}`,
    method: 'put',
    data
  });
}

export function deleteDirector({ directorId }: DeleteDirectorRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/directors/${directorId}`,
    method: 'delete'
  });
}
