import { request } from './utils';

import {
  ChannelResponse,
  ChannelsResponse,
  GetChannelRequestOptions,
  GetChannelsRequestOptions,
  CreateChannelRequestOptions,
  UpdateChannelRequestOptions,
  DeleteChannelRequestOptions,
  DeleteResponse,
  GetChannelCampaignRequestOptions,
  CreateChannelCampaignRequestOptions,
  ChannelCampaignResponse,
  ChannelsTodayRecordResponse,
  ChannelsRecordResponse,
  ChannelUserLineChartRecordsResponse,
  GetChannelAnalyticRequestOptions,
  ChannelRevenueLineChartRecordsResponse,
  DeleteChannelCampaignRequestOptions,
  GetChannelCampaignsRequestOptions,
  GetChannelAdminsRequestOptions,
  ChannelAdminsResponse,
  CreateChannelAdminRequestOptions,
  ChannelAdminResponse,
  DeleteChannelAdminRequestOptions
} from './interfaces';

export function getChannel({ channelId }: GetChannelRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}`,
    method: 'get'
  });
}

export function getChannels({ query }: GetChannelsRequestOptions = {}): Promise<ChannelsResponse> {
  return request({
    url: '/channels',
    method: 'get',
    params: query
  });
}

export function createChannel({ data }: CreateChannelRequestOptions): Promise<ChannelResponse> {
  return request({
    url: '/channels',
    method: 'post',
    data
  });
}

export function updateChannel({ channelId, data }: UpdateChannelRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}`,
    method: 'put',
    data
  });
}

export function deleteChannel({ channelId }: DeleteChannelRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/channels/${channelId}`,
    method: 'delete'
  });
}

export function getChannelCampaign({ channelId, campaignId }: GetChannelCampaignRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}/campaigns/${campaignId}`,
    method: 'get'
  });
}

export function getChannelCampaigns({ channelId }: GetChannelCampaignsRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}/campaigns`,
    method: 'get'
  });
}

export function createChannelCampaign({ channelId, data }: CreateChannelCampaignRequestOptions): Promise<ChannelCampaignResponse> {
  return request({
    url: `/channels/${channelId}/campaigns`,
    method: 'post',
    data
  });
}

export function updateChannelCampaign({ channelId, campaignId, data }: GetChannelCampaignRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}/campaigns/${campaignId}`,
    method: 'put',
    data
  });
}

export function deleteChannelCampaign({ channelId, campaignId }: DeleteChannelCampaignRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/channels/${channelId}/campaigns/${campaignId}`,
    method: 'delete'
  });
}

export function getChannelAdmins({ channelId }: GetChannelAdminsRequestOptions): Promise<ChannelAdminsResponse> {
  return request({
    url: `/channels/${channelId}/admins`,
    method: 'get'
  });
}

export function createChannelAdmin({ channelId, data }: CreateChannelAdminRequestOptions): Promise<ChannelAdminResponse> {
  return request({
    url: `/channels/${channelId}/admins`,
    method: 'post',
    data
  });
}

export function deleteChannelAdmin({ channelId, adminId }: DeleteChannelAdminRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/channels/${channelId}/admins/${adminId}`,
    method: 'delete'
  });
}

export function getChannelUserLineChartRecords({
  channelId,
  query
}: GetChannelAnalyticRequestOptions): Promise<ChannelUserLineChartRecordsResponse> {
  return request({
    url: `/analytics/channel/${channelId}/userLineChart`,
    method: 'get',
    params: query
  });
}

export function getChannelRevenueLineChartRecords({
  channelId,
  query
}: GetChannelAnalyticRequestOptions): Promise<ChannelRevenueLineChartRecordsResponse> {
  return request({
    url: `/analytics/channel/${channelId}/revenueLineChart`,
    method: 'get',
    params: query
  });
}

export function getChannelsTodayRecord(channelId: GetChannelRequestOptions): Promise<ChannelsTodayRecordResponse> {
  return request({
    url: `analytics/channel/${channelId}/todayRecord`,
    method: 'get'
  });
}

export function getChannelsRecord({ channelId, query }: GetChannelRequestOptions): Promise<ChannelsRecordResponse> {
  return request({
    url: `analytics/channel/${channelId}/records`,
    method: 'get',
    params: query
  });
}
