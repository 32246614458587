import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const AdvertisementsRouter: Array<RouteRecordRaw> = [
  {
    path: '/advertisements',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'advertisements',
      icon: '#icon-advertisement'
    },
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/advertisements/Edit.vue'),
        name: 'edit-advertisement',
        meta: {
          title: 'editAdvertisements',
          noCache: true,
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "advertisements-show" */ '@/views/advertisements/Show.vue'),
        name: 'show-advertisement',
        meta: {
          title: 'showAdvertisement',
          noCache: true,
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/advertisements/List.vue'),
        name: 'list-advertisements',
        meta: {
          title: 'advertisements',
          icon: '#icon-advertisement'
        }
      }
    ]
  }
];

export default AdvertisementsRouter;
