import { request } from './utils';

import {
  QuestionResponse,
  QuestionsResponse,
  GetQuestionRequestOptions,
  GetQuestionsRequestOptions,
  CreateQuestionRequestOptions,
  UpdateQuestionRequestOptions,
  DeleteQuestionRequestOptions,
  DeleteResponse
} from './interfaces';

export function getQuestion({ questionId }: GetQuestionRequestOptions): Promise<QuestionResponse> {
  return request({
    url: `/questions/${questionId}`,
    method: 'get'
  });
}

export function getQuestions({ query }: GetQuestionsRequestOptions): Promise<QuestionsResponse> {
  return request({
    url: '/questions',
    method: 'get',
    params: query
  });
}

export function createQuestion({ data }: CreateQuestionRequestOptions): Promise<QuestionResponse> {
  return request({
    url: '/questions',
    method: 'post',
    data
  });
}

export function updateQuestion({ questionId, data }: UpdateQuestionRequestOptions): Promise<QuestionResponse> {
  return request({
    url: `/questions/${questionId}`,
    method: 'put',
    data
  });
}

export function deleteQuestion({ questionId }: DeleteQuestionRequestOptions): Promise<DeleteResponse> {
  return request({
    url: `/questions/${questionId}`,
    method: 'delete'
  });
}
