import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const QuestionRouter: Array<RouteRecordRaw> = [
  {
    path: '/questions',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'questions',
      icon: '#icon-question'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "question-create" */ '@/views/questions/Create.vue'),
        name: 'create-question',
        meta: {
          title: 'createQuestion',
          activeMenu: '/questions',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/edit',
        component: () => import(/* webpackChunkName: "question-edit" */ '@/views/questions/Edit.vue'),
        name: 'edit-question',
        meta: {
          title: 'editQuestion',
          noCache: true,
          activeMenu: '/questions',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "question-show" */ '@/views/questions/Show.vue'),
        name: 'show-question',
        meta: {
          title: 'showQuestion',
          noCache: true,
          activeMenu: '/questions',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "question-list" */ '@/views/questions/List.vue'),
        name: 'list-questions',
        meta: {
          title: 'questions',
          icon: '#icon-question'
        }
      }
    ]
  }
];

export default QuestionRouter;
